var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_c('div',[_c('table',{class:_vm.$route.query.id == 42868 ? 'report42868' : '',attrs:{"id":"my-table"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"align":"center"},attrs:{"id":"activity"}}),_c('th',{staticStyle:{"align":"center"}}),_c('th',{staticStyle:{"align":"center"},attrs:{"id":"note"}}),(_vm.$route.query.id != 42868)?_c('th',{attrs:{"id":"note"}}):_vm._e(),(_vm.$route.query.id == 22842 || _vm.$route.query.id == 42901 )?_c('th',{attrs:{"id":"note"}}):_vm._e()])]),_c('tbody',[_vm._l((_vm.report),function(obj,key){return [_vm._l((obj.childs),function(par,y){return [_vm._l((par.childs),function(rep,x){return [(y == 0 && x == 0 && (obj.name != 'reports.year'|| _vm.$route.query.id == 42902))?_c('tr',{key:key + x + y,attrs:{"id":"countryRow"}},[_c('td',{staticClass:"countryRow",attrs:{"colspan":_vm.clspanFirst()}},[_vm._v(_vm._s(_vm.$lang(obj.value)))])]):_vm._e(),(!rep.name.includes('temp') && rep.childs == 0)?_c('tr',{key:key + x,attrs:{"y":par.value,"x":rep.name,"z":x}},[(y == 0 && x == 0 && obj.name == 'reports.year' && _vm.$route.query.id != 42902)?_c('td',{attrs:{"rowspan":_vm.returnRowspan(obj.childs)}},[_vm._v(_vm._s(obj.value))]):_vm._e(),(x == 0)?_c('td',{attrs:{"rowspan":_vm.rowSpanLevel2(par.childs)}},[_vm._v(" "+_vm._s(!(par.name == "reports.yearContry") ? _vm.$lang(par.name) : par.value)+" ")]):_vm._e(),(rep.name != 'reports.count')?_c('td',{attrs:{"colspan":rep.name == 'reports.totalCountByYear' ||
                      rep.name == 'reports.totalCountRegistered%' ||
                      rep.name == 'reports.totalCountByYearAll' ||
                      rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                        ? 2
                        : 1,"rowspan":_vm.rowSpanLevel2(rep.childs)}},[_vm._v(" "+_vm._s(obj.childs[y].childs[x].name == "reports.totalCountByYear" ? _vm.$lang(rep.name) + _vm.$lang("reports.extension") : _vm.$lang(rep.name))+" ")]):_vm._e(),(
                      rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                    )?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e()]):_vm._l((rep.childs),function(level4,z){return [(
                      !level4.name.includes('temp') &&
                        !rep.name.includes('temp')
                    )?_c('tr',{key:key + z,attrs:{"y":par.value,"x":rep.name,"z":z}},[(
                        y == 0 &&
                          x == 0 &&
                          z == 0 &&
                          obj.name == 'reports.year'&& _vm.$route.query.id != 42902
                      )?_c('td',{attrs:{"rowspan":_vm.returnRowspan(obj.childs)}},[_vm._v(_vm._s(obj.value))]):_vm._e(),(x == 0 && z == 0)?_c('td',{attrs:{"rowspan":_vm.rowSpanLevel2(par.childs)}},[_vm._v(" "+_vm._s(!(par.name == "reports.yearContry") ? _vm.$lang(par.name) : par.value)+" ")]):_vm._e(),(z == 0 && rep.name != 'reports.count')?_c('td',{attrs:{"colspan":rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                          ? 2
                          : 1,"rowspan":_vm.rowSpanLevel2(rep.childs)}},[_vm._v(" "+_vm._s(x == 1 && obj.childs[y].childs[0].name == "reports.totalCountByYearAll" ? _vm.$lang(rep.name) + _vm.$lang("reports.extension") : _vm.$lang(rep.name))+" ")]):_vm._e(),(
                        rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                      )?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.$lang(level4.name)))]),_c('td',[_vm._v(_vm._s(level4.value))])]):_vm._e()]})]})]})]}),(_vm.report.length > 1 && _vm.report[0].name != 'reports.country' && _vm.$route.query.id != 42902)?[_vm._l((_vm.sumData.childs),function(par,y){return [_vm._l((par.childs),function(rep,x){return [(!rep.name.includes('temp') && rep.childs == 0)?_c('tr',{key:_vm.key + x,attrs:{"y":par.value,"x":rep.name,"z":x}},[(y == 0 && x == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspan(_vm.sumData.childs)}},[_vm._v(" "+_vm._s(_vm.sumData.name != "reports.year" ? _vm.$lang(_vm.sumData.value) : _vm.sumData.value)+" ")]):_vm._e(),(x == 0 && par.name != 'reports.count')?_c('td',{attrs:{"rowspan":_vm.rowSpanLevel2(par.childs)}},[_vm._v(" "+_vm._s(!(par.name == "reports.yearContry") ? _vm.$lang(par.name) : par.value)+" ")]):_vm._e(),(par.name != 'reports.count')?_c('td',{attrs:{"colspan":rep.name == 'reports.totalCountByYear' ||
                      rep.name == 'reports.totalCountRegistered%' ||
                      rep.name == 'reports.totalCountByYearAll' ||
                      rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                        ? 2
                        : 1,"rowspan":_vm.rowSpanLevel2(rep.childs)}},[_vm._v(" "+_vm._s(_vm.sumData.childs[y].childs[0].name == "reports.totalCountByYearAll" ? _vm.$lang(rep.name) + _vm.$lang("reports.extension") : _vm.$lang(rep.name))+" ")]):_vm._e(),(
                      rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                    )?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e()]):_vm._l((rep.childs),function(level4,z){return [(
                      !level4.name.includes('temp') &&
                        !rep.name.includes('temp')
                    )?_c('tr',{key:_vm.key + z,attrs:{"y":par.value,"x":rep.name,"z":z}},[(y == 0 && x == 0 && z == 0)?_c('td',{attrs:{"rowspan":_vm.returnRowspan(_vm.sumData.childs)}},[_vm._v(" "+_vm._s(_vm.sumData.name != "reports.year" ? _vm.$lang(_vm.sumData.value) : _vm.sumData.value)+" ")]):_vm._e(),(x == 0 && z == 0)?_c('td',{attrs:{"rowspan":_vm.rowSpanLevel2(par.childs)}},[_vm._v(" "+_vm._s(!(par.name == "reports.yearContry") ? _vm.$lang(par.name) : par.value)+" ")]):_vm._e(),(z == 0)?_c('td',{attrs:{"colspan":rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                          ? 2
                          : 1,"rowspan":_vm.rowSpanLevel2(rep.childs)}},[_vm._v(" "+_vm._s(x == 1 && _vm.sumData.childs[y].childs[0].name == "reports.totalCountByYearAll" ? _vm.$lang(rep.name) + _vm.$lang("reports.extension") : _vm.$lang(rep.name))+" ")]):_vm._e(),(
                        rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                      rep.name== 'reports.unknownAges'
                      )?_c('td',[_vm._v(_vm._s(rep.value))]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.$lang(level4.name)))]),_c('td',[_vm._v(_vm._s(level4.value))])]):_vm._e()]})]})]})]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }