<template>
  <div>
    <span>
      <div>
        <table id="my-table" :class="$route.query.id == 42868 ? 'report42868' : ''">
          <thead>
            <tr>
              <th id="activity" style="align:center;"></th>
              <th style="align:center;"></th>
              <th id="note" style="align:center;"></th>
              <th id="note" v-if="$route.query.id != 42868"></th>
              <th id="note" v-if="$route.query.id == 22842 || $route.query.id == 42901 "></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(obj, key) in report">
              <template v-for="(par, y) in obj.childs">
                <template v-for="(rep, x) in par.childs">
                  <tr
                    id="countryRow"
                    :key="key + x + y"
                    v-if="y == 0 && x == 0 && (obj.name != 'reports.year'|| $route.query.id == 42902)"
                  >
                    <td class="countryRow" :colspan="clspanFirst()">{{ $lang(obj.value) }}</td>
                  </tr>
                  <tr
                    :key="key + x"
                    v-if="!rep.name.includes('temp') && rep.childs == 0"
                    :y="par.value"
                    :x="rep.name"
                    :z="x"
                  >
                    <td
                      :rowspan="returnRowspan(obj.childs)"
                      v-if="y == 0 && x == 0 && obj.name == 'reports.year' && $route.query.id != 42902"
                    >{{ obj.value }}</td>
                    <td :rowspan="rowSpanLevel2(par.childs)" v-if="x == 0">
                      {{
                      !(par.name == "reports.yearContry")
                      ? $lang(par.name)
                      : par.value
                      }}
                    </td>
                    <td
                      v-if="rep.name != 'reports.count'"
                      :colspan="
                        rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                          ? 2
                          : 1
                      "
                      :rowspan="rowSpanLevel2(rep.childs)"
                    >
                      {{
                      obj.childs[y].childs[x].name ==
                      "reports.totalCountByYear"
                      ? $lang(rep.name) + $lang("reports.extension")
                      : $lang(rep.name)
                      }}
                    </td>
                    <td
                      v-if="
                        rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                      "
                    >{{ rep.value }}</td>
                  </tr>
                  <template v-else v-for="(level4, z) in rep.childs">
                    <tr
                      :key="key + z"
                      v-if="
                        !level4.name.includes('temp') &&
                          !rep.name.includes('temp')
                      "
                      :y="par.value"
                      :x="rep.name"
                      :z="z"
                    >
                      <td
                        :rowspan="returnRowspan(obj.childs)"
                        v-if="
                          y == 0 &&
                            x == 0 &&
                            z == 0 &&
                            obj.name == 'reports.year'&& $route.query.id != 42902
                        "
                      >{{ obj.value }}</td>
                      <td :rowspan="rowSpanLevel2(par.childs)" v-if="x == 0 && z == 0">
                        {{
                        !(par.name == "reports.yearContry")
                        ? $lang(par.name)
                        : par.value
                        }}
                      </td>
                      <td
                        v-if="z == 0 && rep.name != 'reports.count'"
                        :colspan="
                          rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                            ? 2
                            : 1
                        "
                        :rowspan="rowSpanLevel2(rep.childs)"
                      >
                        {{
                        x == 1 &&
                        obj.childs[y].childs[0].name ==
                        "reports.totalCountByYearAll"
                        ? $lang(rep.name) + $lang("reports.extension")
                        : $lang(rep.name)
                        }}
                      </td>
                      <td
                        v-if="
                          rep.name == 'reports.totalCountByYear' ||
                            rep.name == 'reports.totalCountRegistered%' ||
                            rep.name == 'reports.totalCountByYearAll' ||
                            rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                        "
                      >{{ rep.value }}</td>
                      <td>{{ $lang(level4.name) }}</td>
                      <td>{{ level4.value }}</td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
            <template
              v-if="report.length > 1 && report[0].name != 'reports.country' && $route.query.id != 42902"
            >
              <template v-for="(par, y) in sumData.childs">
                <template v-for="(rep, x) in par.childs">
                  <tr
                    :key="key + x"
                    v-if="!rep.name.includes('temp') && rep.childs == 0"
                    :y="par.value"
                    :x="rep.name"
                    :z="x"
                  >
                    <td :rowspan="returnRowspan(sumData.childs)" v-if="y == 0 && x == 0">
                      {{
                      sumData.name != "reports.year"
                      ? $lang(sumData.value)
                      : sumData.value
                      }}
                    </td>
                    <td
                      :rowspan="rowSpanLevel2(par.childs)"
                      v-if="x == 0 && par.name != 'reports.count'"
                    >
                      {{
                      !(par.name == "reports.yearContry")
                      ? $lang(par.name)
                      : par.value
                      }}
                    </td>
                    <td
                      v-if="par.name != 'reports.count'"
                      :colspan="
                        rep.name == 'reports.totalCountByYear' ||
                        rep.name == 'reports.totalCountRegistered%' ||
                        rep.name == 'reports.totalCountByYearAll' ||
                        rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                          ? 2
                          : 1
                      "
                      :rowspan="rowSpanLevel2(rep.childs)"
                    >
                      {{
                      sumData.childs[y].childs[0].name ==
                      "reports.totalCountByYearAll"
                      ? $lang(rep.name) + $lang("reports.extension")
                      : $lang(rep.name)
                      }}
                    </td>
                    <td
                      v-if="
                        rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                      "
                    >{{ rep.value }}</td>
                  </tr>
                  <template v-else v-for="(level4, z) in rep.childs">
                    <tr
                      :key="key + z"
                      v-if="
                        !level4.name.includes('temp') &&
                          !rep.name.includes('temp')
                      "
                      :y="par.value"
                      :x="rep.name"
                      :z="z"
                    >
                      <td
                        :rowspan="returnRowspan(sumData.childs)"
                        v-if="y == 0 && x == 0 && z == 0"
                      >
                        {{
                        sumData.name != "reports.year"
                        ? $lang(sumData.value)
                        : sumData.value
                        }}
                      </td>
                      <td :rowspan="rowSpanLevel2(par.childs)" v-if="x == 0 && z == 0">
                        {{
                        !(par.name == "reports.yearContry")
                        ? $lang(par.name)
                        : par.value
                        }}
                      </td>
                      <td
                        v-if="z == 0"
                        :colspan="
                          rep.name == 'reports.totalCountByYear' ||
                          rep.name == 'reports.totalCountRegistered%' ||
                          rep.name == 'reports.totalCountByYearAll' ||
                          rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                            ? 2
                            : 1
                        "
                        :rowspan="rowSpanLevel2(rep.childs)"
                      >
                        {{
                        x == 1 &&
                        sumData.childs[y].childs[0].name ==
                        "reports.totalCountByYearAll"
                        ? $lang(rep.name) + $lang("reports.extension")
                        : $lang(rep.name)
                        }}
                      </td>
                      <td
                        v-if="
                          rep.name == 'reports.totalCountByYear' ||
                            rep.name == 'reports.totalCountRegistered%' ||
                            rep.name == 'reports.totalCountByYearAll' ||
                            rep.name == 'reports.totalCount' ||
                        rep.name== 'reports.unknownAges'
                        "
                      >{{ rep.value }}</td>
                      <td>{{ $lang(level4.name) }}</td>
                      <td>{{ level4.value }}</td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import { lang } from "@/global-functions";
import moment from "moment";
import globalStore from "@/store";
import { ResponsiveDirective } from "vue-responsive-components";
import NoData from "@/components/General/NoData";

Vue.use(Toasted);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    report: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      nameRoute: ""
    };
  },

  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeName = from.name;
      vm.query = from.query;
      vm.nameRoute = from.name;
    });
  },
  methods: {
    clspanFirst() {
      if (this.$route.query.id == 22842 || this.$route.query.id == 42901)
        return 5;
      else if (this.$route.query.id == 42868) return 3;
      else return 4;
    },
    returnRowspan(obj) {
      var rowNumber = 0;

      for (let i = 0; i < obj.length; i++) {
        for (let j = 0; j < obj[i].childs.length; j++) {
          if (
            obj[i].childs[j] &&
            !obj[i].childs[j].name.includes("temp") &&
            obj[i].childs[j].value
          )
            rowNumber++;
          for (let k = 0; k < obj[i].childs[j].childs.length; k++)
            if (
              obj[i].childs[j].childs[k] &&
              !obj[i].childs[j].childs[k].name.includes("temp")
            )
              rowNumber++;
        }
      }
      return rowNumber ? rowNumber : 1;
    },
    rowSpanLevel2(obj) {
      var rowNumber = 0;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] && !obj[i].name.includes("temp") && obj[i].value != null)
          rowNumber++;
        for (let j = 0; j < obj[i].childs.length; j++) {
          if (obj[i].childs[j] && !obj[i].childs[j].name.includes("temp"))
            rowNumber++;
        }
      }

      return rowNumber ? rowNumber : 1;
    },
    returnLastSum(rootName, name) {
      var sum = 0;
      var $this = this;
      for (let i = 0; i < $this.report.length; i++) {
        for (let j = 0; j < $this.report[i].childs.length; j++) {
          for (let k = 0; k < $this.report[i].childs[j].childs.length; k++) {
            for (
              let l = 0;
              l < $this.report[i].childs[j].childs[k].childs.length;
              l++
            ) {
              if (
                $this.report[i].childs[j].childs[k].childs[l].name == name &&
                $this.report[i].childs[j].childs[k].name == rootName
              ) {
                if ($this.report[i].childs[j].childs[k].childs[l].value != null)
                  sum += Number(
                    $this.report[i].childs[j].childs[k].childs[l].value
                  );
                else return null;
              }
            }
          }
        }
      }
      return sum;
    }
  },
  watch: {},
  computed: {
    sumData() {
      var totalSum = {};
      if (this.report.length > 1 && this.report[0].name != "reports.country") {
        var $this = this;
        var yFrom = 0,
          yTo = 0;
        var yTo = Math.max.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );
        var yFrom = Math.min.apply(
          Math,
          $this.report.map(function(o) {
            return Number(o.value);
          })
        );

        var lastObject = {
          value: yFrom + "-" + yTo,
          name: "reports.year",
          childs: $this.$_.cloneDeep($this.report[0].childs)
        };

        for (let i = 0; i < lastObject.childs.length; i++) {
          for (let j = 0; j < lastObject.childs[i].childs.length; j++) {
            for (
              let k = 0;
              k < lastObject.childs[i].childs[j].childs.length;
              k++
            ) {
              lastObject.childs[i].childs[j].childs[
                k
              ].value = $this.returnLastSum(
                lastObject.childs[i].childs[j].name,
                lastObject.childs[i].childs[j].childs[k].name
              );
            }
          }
        }
        totalSum = lastObject;
      }
      return totalSum;
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  text-align: left !important;
}
.countryRow {
  text-align: center !important;
  font-weight: bold;
  background-color: rgb(176, 224, 230);
}

.report42868 {
  td {
    width: 45%;
  }
}
</style>
